import { object, string } from 'yup';

const VALID_TEXT = /^[a-zA-Z\d.\- ]*$/;

const isValidText = (val: string = '') => {
  if (val === '') {
    return true;
  }

  return VALID_TEXT.test(val);
};

const validText = {
  name: 'validText',
  message: 'Please use only letters, spaces, hyphens or underscore',
  test: isValidText
};

const searchSchema = object({
  search: string().min(1, 'Search cannot be less than ${min} characters in length').test(validText)
});

export { searchSchema };
