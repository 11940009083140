import {
  AllFilesState,
  FileListItem,
  FilesActionTypes,
  FilesAllAction,
  FilesState,
  GetFilesListResponse,
  GetOrgFilesResponse,
  OrgFilesState
} from './types';
import { initialOrgFilesParams, initialFilesListParams, initialState } from './selectors';
import { combineReducers } from '@reduxjs/toolkit';

export const setFiles = (data: GetFilesListResponse) => ({
  type: FilesActionTypes.SET_FILES,
  data
});

export const setFilesPage = (data: number) => ({
  type: FilesActionTypes.SET_FILES_PAGE,
  data
});

export const setFilesPageSize = (data: number) => ({
  type: FilesActionTypes.SET_FILES_PAGE_SIZE,
  data
});

export const setFilesFilters = (data: { status?: string; uploadedAt?: string, companyCode?: string }) => ({
  type: FilesActionTypes.SET_FILES_FILTERS,
  data
});

export const resetFilesFilters = () => ({
  type: FilesActionTypes.RESET_FILES_FILTERS
});

export const updateListFile = (data: Partial<FileListItem>) => ({
  data,
  type: FilesActionTypes.UPDATE_LIST_FILE
});

export const setCompanyFiles = (data: GetOrgFilesResponse, companyCode: string) => ({
  type: FilesActionTypes.SET_ORG_FILES,
  data,
  companyCode
});

export const setCompanyFilesPageSize = (data: number) => ({
  type: FilesActionTypes.SET_ORG_FILES_PAGE_SIZE,
  data
});

export const setCompanyFilesPage = (data: number) => ({
  type: FilesActionTypes.SET_ORG_FILES_PAGE,
  data
});

export const setCompanyFilesFilters = (data: { status?: string; uploadedAt?: string, search?: string }) => ({
  type: FilesActionTypes.SET_ORG_FILES_FILTERS,
  data
});

export const resetCompanyFilesFilters = () => ({
  type: FilesActionTypes.RESET_ORG_FILES_FILTERS
});

function orgFiles(filesState = initialState.orgFiles, action: FilesAllAction): OrgFilesState {
  switch (action.type) {
    case FilesActionTypes.SET_ORG_FILES: {
      const {
        data: { files, ...restParams }
      } = action;

      return {
        ...filesState,
        list: files,
        params: { ...(filesState?.params || initialOrgFilesParams), ...restParams }
      };
    }
    case FilesActionTypes.SET_ORG_FILES_PAGE: {
      const page = action.data;
      return {
        ...filesState,
        params: {
          ...(filesState?.params || initialOrgFilesParams),
          page
        }
      };
    }
    case FilesActionTypes.SET_ORG_FILES_PAGE_SIZE: {
      const pageSize = action.data;
      return {
        ...filesState,
        params: {
          ...(filesState?.params || initialOrgFilesParams),
          page: 0,
          pageSize
        }
      };
    }
    case FilesActionTypes.SET_ORG_FILES_FILTERS: {
      const filters = action.data ?? {};
      return {
        ...filesState,
        params: {
          ...(filesState?.params || initialOrgFilesParams),
          ...filters,
        }
      };
    }
    case FilesActionTypes.RESET_ORG_FILES_FILTERS: {
      return {
        ...filesState,
        params: initialOrgFilesParams
      };
    }
    default:
      return filesState;
  }
}

function allFiles(filesState = initialState.allFiles, action: FilesAllAction): AllFilesState {
  switch (action.type) {
    case FilesActionTypes.SET_FILES: {
      const {
        data: { files, total, totalPages, ...restParams }
      } = action;

      return {
        list: files,
        params: {
          ...(filesState?.params || initialFilesListParams),
          ...restParams,
          total: total ?? 0,
          totalPages: totalPages ?? 0,
        }
      };
    }
    case FilesActionTypes.SET_FILES_PAGE: {
      const page = action.data;
      return {
        ...filesState,
        params: {
          ...(filesState?.params || initialFilesListParams),
          page
        }
      };
    }
    case FilesActionTypes.SET_FILES_PAGE_SIZE: {
      const pageSize = action.data;
      return {
        ...filesState,
        params: {
          ...(filesState?.params || initialFilesListParams),
          page: 0,
          pageSize
        }
      };
    }
    case FilesActionTypes.SET_FILES_FILTERS: {
      const filters = action.data;
      return {
        ...filesState,
        params: {
          ...(filesState?.params || initialFilesListParams),
          ...filters,
          page: 0
        }
      };
    }
    case FilesActionTypes.RESET_FILES_FILTERS: {
      return {
        ...filesState,
        params: initialFilesListParams
      };
    }
    case FilesActionTypes.UPDATE_LIST_FILE: {
      const updatedList = [...filesState.list].map((file) =>
        file.id === action.data.id ? { ...file, ...action.data } : file
      );
      return {
        ...filesState,
        list: updatedList
      };
    }
    default:
      return filesState;
  }
}

export const files = combineReducers<FilesState>({ orgFiles, allFiles });
