// Global
import './styles.scss';
import React from 'react';

// Components
import { Dot } from 'components/Dot';
import { FileListItem } from 'components/FileListItem';
import { Heading } from 'components/Heading';
import { LayoutPage } from 'components/LayoutPage';
import { FileUploadType, NoFiles } from 'components/NoFiles';
import { Paragraph } from 'components/Paragraph';
import { Search } from 'components/Search';
import { TableList } from 'components/Table/List';

// Services
import { doGetCompanyFiles } from 'services/files/async';
import {
  getCompanyFiles,
  getCompanyFilesParamsPage,
  getCompanyFilesParamsPageSize,
  getCompanyFilesParamsTotal,
  getCompanyFilesParamsTotalPages
} from 'services/files/selectors';
import { getEntitlementsHasFilesAccess } from 'services/auth/selectors';
import {
  resetCompanyFilesFilters,
  setCompanyFilesFilters,
  setCompanyFilesPage,
  setCompanyFilesPageSize,
} from 'services/files/reducers';

// Misc
import format from 'date-fns/format';
import { EbDispatch } from 'store';
import { OrgFile } from 'services/files/types';
import { getFileLabel } from 'utils/statuses';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectDidUpdate } from 'hooks/useEffectDidUpdate';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchButton } from 'hooks/useSearchButton';
import { Filter } from '../../components/Filter';
import { FILTER_OPTIONS } from '../Files/data';
import { useFilter } from '../../hooks/useFilterToggle';

// Const
const DATE_FORMAT = 'MMM d, yyyy, hh:mm a';

function FileManagement() {
  // Hooks
  const dispatch: EbDispatch = useDispatch();
  const navigate = useNavigate();
  const { companyCode } = useParams();

  // Hooks - state
  const [filtersCount, setFiltersCount] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');

  //Hooks - custom
  const { searchButton, isSearchInputActive } = useSearchButton();
  const { filterButton, isFilterOpen, toggleFilter } = useFilter(filtersCount);

  // Hooks - selectors
  const userHasFilesAccess = useSelector(getEntitlementsHasFilesAccess);
  const files = useSelector(getCompanyFiles);
  const filesTotal = useSelector(getCompanyFilesParamsTotal);

  // Hooks - effects
  React.useEffect(() => {
    if (!userHasFilesAccess) navigate('/');
  }, [userHasFilesAccess, navigate]);

  React.useEffect(
    () => () => {
      dispatch(resetCompanyFilesFilters());
    },
    [dispatch]
  );

  useEffectDidUpdate(() => {
    if (!isSearchInputActive) {
      setSearchText('');
      void dispatch(setCompanyFilesFilters({ search: ''}));
      void dispatch(doGetCompanyFiles());
    }
  }, [isSearchInputActive, dispatch]);

  const suggestions = (
    <>
      <Paragraph>Suggestions:</Paragraph>
      <Paragraph>Make sure that file name is spelled correctly.</Paragraph>
      <Paragraph>Try different file name.</Paragraph>
    </>
  );

  // Handler
  const onSearch = async (text: string) => {
    const search = text?.toLowerCase();
    setSearchText(search);
    dispatch(setCompanyFilesFilters({ search }));
    await dispatch(doGetCompanyFiles());
  };

  const onSearchCancel = () => {
    setSearchText('');
    void dispatch(setCompanyFilesFilters({ search: ''}));
    void dispatch(doGetCompanyFiles());
  };

  const handleFilter = (filters: Record<string, string>) => {
    setFiltersCount(Object.keys(filters).length);
    dispatch(resetCompanyFilesFilters());
    dispatch(setCompanyFilesFilters({ ...filters, search: searchText }));
    void dispatch(doGetCompanyFiles());
    toggleFilter();
  };

  // Render
  const renderHeading = () => (
    <div className="eb-file_management-heading">
      <Heading size={32} type="h1">
        File Management
      </Heading>
      <div className="eb-file_management-heading-filter-buttons">
        {searchButton}
        {filterButton}
      </div>
    </div>
  );

  const renderFile = (file: OrgFile) => {
    const uploadedBy = file.submittedBy
      ? ` by ${file.submittedBy.username ?? file.submittedBy.username ?? ''}`
      : '';
    const uploadedDate = format(new Date(file.uploadedAt), DATE_FORMAT);
    const successCount = file?.successCount ?? 0;
    const failureCount = file?.failureCount ?? 0;
    const noActionCount = file?.noActionCount ?? 0;

    const totalCount = successCount+failureCount+noActionCount ?? 0
    const footer = (
      <div className="eb-file_management-file-info">
        {getFileLabel(file.name)}
        {uploadedDate && (
          <>
            <Dot color="gray" />
            <div>
              {uploadedDate}
              {uploadedBy}
            </div>
          </>
        )}
        <div className="eb-file_management-summary-counts">
          <div className="eb-file_management-summary-counts-item">
            <span>Total</span>
            <span>{totalCount}</span>
          </div>
          <div className="eb-file_management-summary-counts-item">
            <span>Accepted</span>
            <span>{successCount}</span>
          </div>
          <div className="eb-file_management-summary-counts-item">
            <span>Rejected</span>
            <span>{failureCount}</span>
          </div>
          <div className="eb-file_management-summary-counts-item">
            <span>No action</span>
            <span>{noActionCount}</span>
          </div>
        </div>
      </div>
    );

    return (
      <FileListItem
        author={uploadedBy}
        companyCode={companyCode}
        errorsCount={file.failureCount}
        fileId={file.id}
        fileName={file.name}
        footer={footer}
        icon="file"
        key={file.id}
        status={file.status}
        updated={uploadedDate}
      />
    );
  };

  const renderChildren = () => {
    const loadingText = isSearchInputActive ? 'Searching...' : 'Loading...';
    const empty = isSearchInputActive ? null : (
      <NoFiles fileUploadType={FileUploadType.ENROLLMENT} />
    );

    return (
      <TableList
        empty={empty}
        loadingText={loadingText}
        requests={[doGetCompanyFiles]}
        selectorItemsTotal={getCompanyFilesParamsTotal}
        selectorPage={getCompanyFilesParamsPage}
        selectorPageSize={getCompanyFilesParamsPageSize}
        selectorPagesTotal={getCompanyFilesParamsTotalPages}
        setPage={(page) => dispatch(setCompanyFilesPage(page))}
        setPageSize={(pageSize) => dispatch(setCompanyFilesPageSize(pageSize))}
        title="File History"
      >
        {files.map((file) => renderFile(file))}
      </TableList>
    );
  };

  return (
    <LayoutPage className="eb-file_management" heading={renderHeading()}>
      <Search
        isVisible={isSearchInputActive}
        onCancel={onSearchCancel}
        onSubmit={onSearch}
        placeHolder="Search by file name"
        request={doGetCompanyFiles}
        suggestions={suggestions}
        totalResultsFound={filesTotal}
      />
      <Filter
        isVisible={isFilterOpen}
        onApply={handleFilter}
        onCancel={toggleFilter}
        options={FILTER_OPTIONS}
        request={doGetCompanyFiles}
      />

      {renderChildren()}
    </LayoutPage>
  );
}

export { FileManagement };
