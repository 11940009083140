// Globals
import './styles.scss';
import React from 'react';

// Components
import { Heading } from 'components/Heading';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';

// Types
import { FILE_UPLOAD_ERROR } from 'hooks/useFileUploadManager/types';

// Props
export interface ModalFileUploadFailureProps {
  error: FILE_UPLOAD_ERROR | string;
  onBack: () => void;
}

// Component
const ModalFileUploadFailure: React.FC<ModalFileUploadFailureProps> = ({ error, onBack }) => {
  const getErrorText = () => {
    switch (error) {
      case FILE_UPLOAD_ERROR.BAD_FILE_TYPE:
        return (
          <Heading
            align="center"
            className="eb-modal_file_upload_failure-heading"
            size={22}
            type="h2"
          >
            Please upload a CSV file
          </Heading>
        );
      default:
        return (
          <Heading
            align="center"
            className="eb-modal_file_upload_failure-heading"
            size={22}
            type="h2"
          >
            We couldn&apos;t read this file
          </Heading>
        );
    }
  };

  // Render
  return (
    <div className="eb-modal_file_upload_failure">
      <Icon className="eb-modal_file_upload_failure-img" name="warning" />

      {getErrorText()}

      <Button className="eb-modal_file_upload_failure-button" onClick={onBack}>
        OK
      </Button>
    </div>
  );
};

export { ModalFileUploadFailure };
